import React from 'react';
import './custom.css';
import rosesImage from './img/roses.jpg';

function App() {
  const divStyle = {
    backgroundImage: `url(${rosesImage})`,
    backgroundColor: 'white',
  };

  // function goHome() {
  //   let pageUrl = 'https://swannenini.com';
  //   document.location.href = pageUrl;
  // }

  return (
    <div className='roses' style={divStyle}>
      <div className='christen_pray'>
        <div className='christen_inner'>
          <div className='header'>
            <div className='header_left'>
              <a href='https://swannenini.com'>
                {/* <a href='{()=>false}' onClick={goHome}> */}
                <img src='./img/swanne+national.png' className='header_logo_img' alt="あまつみはは" />
              </a>
            </div>
            <div className='header_right'>
              <span>祈り</span>
              <span>ミサ</span>
              <span>福音</span>
            </div>
          </div>
          <div className='pray_body'>
            <h4>聖母 【あまつみはは】 （＃302）</h4>
            <p className='pcenter'>
              <img src='img/csei302.gif' alt="あまつみはは カトリック聖歌集 #302" />
              {/* <img src={process.env.PUBLIC_URL + '/img/csei302.gif'} alt="Image" /> */}
            </p>
            <br />
            <table className='hbs'>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    あまつみはは 聖マリア
                    <br />
                    まごころもて 世の子らが
                    <br />
                    ささげまつる たたえ歌
                    <br />
                    きよきしらべ 受けたまえ
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    み手にいだく みどり子を
                    <br />
                    そだてましし いつくしみ
                    <br />
                    あまつかみも よろこびて
                    <br />
                    たかきみ名を たまいけり
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    いとしき み子 すこやかに
                    <br />
                    そだちませば いけにえと
                    <br />
                    ささげたもう おんははの
                    <br />
                    奇（く）しき みわざ ほめよかし
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    わがすべてを いまささげ
                    <br />
                    きみにならい ひとすじに
                    <br />
                    神のみむね おこないて
                    <br />
                    あまつくにに 昇りゆかん
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
